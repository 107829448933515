<template>
  <div class="h-min-screen flex flex-col">
    <Disclosure
      v-if="$route.name != 'm'"
      v-slot="{ open }"
      as="nav"
      class="sticky top-0 bg-white z-40"
      :class="{ shadow: $route.name != 'search' }"
    >
      <div class="mx-auto max-w-full px-6">
        <div class="flex h-16 items-center justify-between">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <NuxtLink
                :to="user?.provider ? getProviderLink(user.provider) : '/'"
              >
                <img class="h-12 w-21" src="/logo.png" alt="Logo" />
              </NuxtLink>
            </div>
          </div>
          <div v-if="$route.name != 'index'" class="flex-1 max-w-sm ml-3">
            <SearchBar v-model="query" mini="true" class="hidden sm:block" />
            <MobileSearchBar v-model="query" />
          </div>
          <div class="flex items-center">
            <NuxtLink
              v-if="route.name != 'new' && !user?.providerId"
              href="/addProvider"
              no-prefetch
              class="btn btn-outline text-xs rounded-3xl text-gray-500 font-normal h-8 min-h-0"
              :class="{ 'hidden md:inline-flex': $route.name != 'index' }"
            >
              Add Provider
            </NuxtLink>

            <NuxtLink
              v-if="
                user &&
                user.provider &&
                (!user.isEhrEnabled || $route.name.search('manage') === 0)
              "
              :href="getProviderLink(user.provider)"
              no-prefetch
              class="btn btn-secondary text-xs rounded-3xl font-normal h-8 min-h-0"
              :class="{ 'hidden md:inline-flex': $route.name != 'index' }"
            >
              My Profile
            </NuxtLink>
            <NuxtLink
              v-if="
                user && user.isEhrEnabled && $route.name.search('manage') !== 0
              "
              href="/manage/calendar"
              no-prefetch
              class="btn btn-outline text-sm rounded-3xl font-normal h-8 min-h-0"
              :class="{ 'hidden md:inline-flex': $route.name != 'index' }"
            >
              Manage
              <div v-if="badge" class="badge badge-secondary">
                {{ badge }}
              </div>
              <ChevronRightIcon class="h-4 w-4" />
            </NuxtLink>

            <div class="ml-2 flex items-center">
              <MainMenu />
            </div>
          </div>
        </div>
      </div>
    </Disclosure>

    <main class="flex-auto">
      <slot />
    </main>
    <div v-if="toast" class="toast toast-top toast-center text-sm z-[100]">
      <div
        class="alert flex"
        :class="{
          'alert-error': toast.type === 'error',
          'alert-success': toast.type === 'success',
          'alert-warning': toast.type === 'warning',
          'alert-info': toast.type === 'info',
        }"
      >
        <span>{{ toast.message }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';
import { ChevronRightIcon } from '@heroicons/vue/24/solid';

const route = useRoute();

const user = useUser();
const toast = useToast();
const badge = useNotificationBadge();

useHead({
  bodyAttrs: {
    class: 'h-full',
  },
  htmlAttrs: {
    class: () =>
      `h-full ${route.hash === '#m' || route.name === 'search' ? 'overscroll-none' : ''}`,
    lang: 'en',
  },
  titleTemplate: '%s - MyProviders',
  meta: [
    { property: 'og:type', content: 'website' },
    { property: 'og:site_name', content: 'MyProviders' },
    { property: 'og:image', content: `${useAppConfig().cdnURL}/og_img.png` },
    { property: 'og:image:width', content: '1200' },
    { property: 'og:image:height', content: '630' },
  ],
});

useSchemaOrg([
  defineOrganization({
    name: 'MyProviders',
  }),
  defineWebSite({
    name: 'MyProviders',
  }),
]);

const query = ref(route.query.q);
watch(
  () => route.query.q,
  () => {
    query.value = route.query.q;
  },
);
</script>
