<template>
  <Menu as="div" class="relative">
    <div>
      <MenuButton
        id="user-menu"
        class="flex max-w-xs items-center rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2"
      >
        <span class="sr-only">Open user menu</span>
        <div v-if="user" class="avatar placeholder">
          <div class="bg-pink-300 text-gray-600 rounded-full w-8 font-medium">
            <span>{{ getAvatarName(user) }}</span>
          </div>
        </div>
        <UserCircleIcon v-else class="h-8 w-8 text-gray-500" />
      </MenuButton>
    </div>
    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg divide-y ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div v-for="(section, i) in nav" :key="i">
          <MenuItem
            v-for="item in section"
            :key="item.name"
            v-slot="{ active, close }"
          >
            <a
              :href="item.href"
              class="block px-4 py-2 text-sm text-gray-700"
              :class="{
                'bg-gray-100': active,
                'font-semibold': item.bold,
              }"
              @click.prevent="
                handleUserMenu(item);
                close();
              "
              >{{ item.name }}</a
            >
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
    <Modal v-if="isSigninOpen" @close="isSigninOpen = false">
      <Signin auth-mode="signup" @close="isSigninOpen = false" />
    </Modal>
  </Menu>
</template>
<script setup>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { UserCircleIcon } from '@heroicons/vue/24/solid';

const user = useUser();
const isSigninOpen = ref(false);
const badge = useNotificationBadge();

const getAvatarName = (user) => {
  if (user.name) {
    return user.name[0];
  }

  if (user.email) {
    return user.email[0].toUpperCase();
  }

  return 'U';
};

const anonymousNav = [
  [
    {
      name: 'Sign in',
      href: '#',
      onClick: () => {
        isSigninOpen.value = true;
      },
    },
    {
      name: 'Sign up',
      href: '#',
      onClick: () => {
        isSigninOpen.value = true;
      },
    },
  ],
  [
    { name: 'Add provider', href: '/addProvider' },
    { name: 'Contact us', href: '/contact' },
  ],
];
const signout = () => {
  signOutUser();
  navigateTo('/');
};

const handleUserMenu = (item) => {
  if (item.href !== '#') {
    navigateTo(item.href);
    return;
  }
  item.onClick();
};

const userNav = [
  [
    { name: 'Account', href: '/profile' },
    { name: 'Appointments', href: '/appointments' },
  ],
  [
    { name: 'Add provider', href: '/addProvider' },
    { name: 'Contact us', href: '/contact' },
    { name: 'Sign out', href: '#', onClick: () => signout() },
  ],
];

const nav = computed(() => {
  if (user.value && user.value.provider) {
    return [
      ...(user.value.isEhrEnabled
        ? [
            [
              {
                name: 'Calendar',
                href: '/manage/calendar',
                bold: true,
              },
              {
                name: 'Clients',
                href: '/manage/clients',
                bold: true,
              },
              {
                name: 'Inbox' + (badge.value ? ` (${badge.value})` : ''),
                href: '/manage/inbox',
                bold: true,
              },
            ],
          ]
        : []),
      [
        { name: 'My Profile', href: getProviderLink(user.value.provider) },
        ...(user.value.isEhrEnabled
          ? [
              {
                name: 'Settings',
                href: '/manage/settings',
              },
            ]
          : []),
      ],
      [
        { name: 'Contact us', href: '/contact' },
        { name: 'Sign out', href: '#', onClick: () => signout() },
      ],
    ];
  }

  if (user.value) {
    return userNav;
  }
  return anonymousNav;
});
</script>
